import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Logo } from './Logo'
import { MotifProgressLoader, MotifProgressBar } from '@ey-xd/motif-react'

import c from './login.module.css'
import { MotifButton, MotifCard } from '../../motif/components'
import { AuthContext } from '../../context'

export function Login() {
  const { initiateSignIn, loading } = useContext(AuthContext)

  return (
    <div className={c.container}>
      {loading ? 
        <MotifProgressBar
          hideLabel
          circle
          isIndeterminate /> :
        <MotifCard className={c.card}>
          <Logo />
          <h2>
            {loading ? (
              <FormattedMessage id='login.signInSentence' defaultMessage='Signing in...' />
            ) : (
              <FormattedMessage
                id='login.signInSentence'
                defaultMessage='Sign in to EY VIA Intake Form'
              />
            )}
          </h2>
          {loading ? (
            <div style={{ position: 'relative' }}>
              <MotifProgressLoader show />
            </div>
          ) : (
            <MotifButton onClick={initiateSignIn} variant='primary-alt'>
              <FormattedMessage id='login.signIn' defaultMessage='Sign in' />
            </MotifButton>
          )}
        </MotifCard>}
    </div>
  )
}
