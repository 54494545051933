import { createContext } from 'react'
import { Notification } from './type'

export const NotificationContext = createContext({
  info: (opts: Omit<Notification, 'variant' | 'id'>) => undefined as void,
  warning: (opts: Omit<Notification, 'variant' | 'id'>) => undefined as void,
  error: (opts: Omit<Notification, 'variant' | 'id'>) => undefined as void,
  success: (opts: Omit<Notification, 'variant' | 'id'>) => undefined as void,
  hasNotification: (f: (n: Notification) => boolean) => false as boolean,
})
