import { configuredFetch } from './request-utils'

export class FetchError extends Error {
  constructor(public res: Response) {
    super()
  }
}

async function getPermissions(): Promise<unknown> {
  const res = await configuredFetch(`/permissions`)
  if (!res.ok) throw new FetchError(res)
  return await res.json()
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getPermissionsMocked() {
  return Promise.resolve([
    { action: 'create', subject: 'Request' },
    { action: 'read', subject: 'Request' },
    { action: 'update', subject: 'Request' },
    { action: 'delete', subject: 'Request' },
    { action: 'approve', subject: 'Request' },
    { action: 'create', subject: 'User' },
    { action: 'read', subject: 'User' },
    { action: 'update', subject: 'User' },
    { action: 'delete', subject: 'User' },
    { action: 'read', subject: 'Report'},
  ])
}

export { getPermissions }
