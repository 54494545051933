import React, { PropsWithChildren } from 'react'
import c from './empty.module.css'

export const Empty = (props: PropsWithChildren<{}>) => {
  return (
    <div className={c.empty}>
      <div>
        <img src='/empty-state.svg' alt='No data' />
      </div>
      <h1>
        {props.children}
      </h1>
    </div>
  )
}
