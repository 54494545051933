import { useContext } from 'react'
import { MotifIcon, MotifIconButton } from '../../motif/components'
import {
  actionIcDashboard24px,
  contentIcAddCircle24px,
  socialIcPeople24px,
  actionIcAccountBalanceWallet24px,
  actionIcDateRange24px
} from '../../motif/icons'

import { NavLink, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { AbilityContext } from '../../context/permissions'
import { Subject } from '@casl/ability'

import c from './SideBar.module.css'

const questionMark20px = `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20"%3E%3Cpath d="M10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm1 17H9v-2h2v2Zm2.07-7.75-.9.92A3.4 3.4 0 0 0 11 13H9v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41a2 2 0 0 0-2-2 2 2 0 0 0-2 2H6a4 4 0 1 1 8 0c0 .88-.36 1.68-.93 2.25Z"/%3E%3C/svg%3E`
const reportsIcon24px = `data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath opacity=".8" fill-rule="evenodd" clip-rule="evenodd" d="M4.25195 14c.88809 3.4505 4.02029 6 7.74795 6 4.4183 0 8-3.5817 8-8 0-4.07962-3.0537-7.44601-7-7.93811V14H4.25195Z"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M11.0006 10V3c-4.07961 0-7.446 3.05369-7.9381 7h7.9381Z"/%3E%3C/svg%3E`

const SideBar = () => {
  const { pathname } = useLocation()
  const userAbility = useContext(AbilityContext)
  const intl = useIntl()

  const help = intl.formatMessage({ id: 'menu.help', defaultMessage: 'Help' })

  const menus = [
    {
      path: '/requests',
      icon: actionIcDashboard24px,
      title: intl.formatMessage({ id: 'menu.request', defaultMessage: 'Intake Requests' }),
      condition: ['read', 'Request'] as [string, Subject],
      end: pathname === '/requests/new',
    },
    {
      path: '/users',
      icon: socialIcPeople24px,
      title: intl.formatMessage({ id: 'menu.users', defaultMessage: 'Manage Users' }),
      condition: ['read', 'User'] as [string, Subject],
    },
    {
      path: '/requests/new',
      icon: contentIcAddCircle24px,
      title: intl.formatMessage({
        id: 'menu.newRequest',
        defaultMessage: 'Create Intake Request',
      }),
      condition: ['create', 'Request'] as [string, Subject],
      end: true,
    },
    {
      path: '/reports',
      icon: reportsIcon24px,
      title: intl.formatMessage({ id: 'menu.reports', defaultMessage: 'Reports' }),
      condition: ['read', 'Report'] as [string, Subject],
    },
    {
      path: '/cost-monitoring',
      icon: actionIcAccountBalanceWallet24px,
      title: intl.formatMessage({ id: 'menu.costMonitoring', defaultMessage: 'Cost Monitoring' }),
      condition: ['read', 'CostMonitoring'] as [string, Subject],
    },
    {
      path: '/infra-optimiztion',
      icon: actionIcDateRange24px,
      title: intl.formatMessage({ id: 'menu.infraOptimization', defaultMessage: 'Infrastructure optimization' }),
      condition: ['read', 'InfrastructureOptimization'] as [string, Subject],
    },
    {
      path: '/scale-up-down-database',
      icon: actionIcDateRange24px,
      title: intl.formatMessage({ id: 'menu.scaleUpDownDatabase', defaultMessage: 'Scale up down database' }),
      condition: ['read', 'InfrastructureOptimization'] as [string, Subject],
    }
  ].filter((m) => {
    if (m.condition !== undefined) {
      return userAbility.can(...m.condition)
    }
    return true
  })

  return (
    <aside className={c.sidebar}>
      {menus.map((m) => (
        <NavLink key={m.path} to={m.path} className={c.link} end={m.end} title={m.title}>
          <MotifIcon src={m.icon} className={c.itemIcon} />
          <div className={c.itemLabelContainer}>
            <div className={c.itemLabel}>{m.title}</div>
          </div>
        </NavLink>
      ))}

      <MotifIconButton onClick={() => window.open("https://sites.ey.com/sites/via/SitePages/IntakeManagement.aspx", "_blank")}>
        <MotifIcon className={c.itemIcon} src={questionMark20px} title={help} />
      </MotifIconButton>
    </aside>
  )
}

export { SideBar }
