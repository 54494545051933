import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  MotifButton,
  MotifFooter,
  MotifModal,
  MotifModalBody,
  MotifModalHeader,
} from '../../motif/components'

type ModalProps = {
  children?: ReactNode
  show?: boolean
  onClose?: () => void
  confirm?: () => void
  title?: string
  size?: string
  disabledConfirm?: boolean
  disableFooter?: boolean
}

const Modal = (props: ModalProps) => {
  return (
    <MotifModal show={props.show} onClose={props.onClose} size={props.size}>
      <MotifModalHeader>
        <FormattedMessage id='modal.title' defaultMessage={props.title} />
      </MotifModalHeader>
      <MotifModalBody>{props.children}</MotifModalBody>
      {!props.disableFooter && (
        <MotifFooter style={{ display: 'flex', gap: '1rem' }}>
          <MotifButton
            onClick={props.confirm}
            variant='primary-alt'
            disabled={props.disabledConfirm}
          >
            <FormattedMessage id='delete' defaultMessage='Save' />
          </MotifButton>
          <MotifButton onClick={props.onClose} variant='secondary'>
            <FormattedMessage id='cancel' defaultMessage='Cancel' />
          </MotifButton>
        </MotifFooter>
      )}
    </MotifModal>
  )
}

Modal.defaultProps = {
  show: false,
  onClose: () => {},
  confirm: () => {},
  title: '',
  size: 'md',
  disabledConfirm: false,
  disableFooter: false,
}

export { Modal }
