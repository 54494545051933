import { getToken, getUser } from './auth'
import { api } from './config'
import { MD5 } from 'crypto-js'

export class FetchError extends Error {
  constructor(public res: Response) {
    super()
  }
}

export type Paging = {
  page: number
  pageCount: number
  size: number
  total: number
}

type FunctionAbortable<Result, Args extends [...any[]]> = (
  ...args: [...Args, AbortSignal | undefined]
) => Promise<Result>
export const withAbort =
  <Args extends [...any], Result>(fn: FunctionAbortable<Result, Args>) =>
  (...args: [...Args]): [Promise<Result>, AbortController] => {
    const ctrl = new AbortController()
    const changedArgs: [...Args, AbortSignal] = [...args, ctrl.signal]
    return [fn(...changedArgs), ctrl]
  }

async function getVisitorId() {
  const user = await getUser()
  return MD5(
    `${user?.localAccountId}${navigator.userAgent}${!!(navigator as any).brave}`
  ).toString()
}

async function requestOptions(
  opt: RequestInit & { json?: Record<string | number, any> } = {}
): Promise<RequestInit> {
  const token = await getToken()
  const headers = {
    ...(opt.headers ?? {}),
    Authorization: `Bearer ${token}`,
    'x-device-key': await getVisitorId(),
  } as Record<string, string>

  const out = {
    ...opt,
    headers,
  }

  if (opt.json) {
    delete out.json
    out.body = JSON.stringify(opt.json)
    headers['content-type'] = 'application/json'
  }
  return out
}

export async function requestHeaders(
  opt: RequestInit & { json?: Record<string | number, any> } = {}
): Promise<any> {
  const token = await getToken()
  const headers = {
    ...(opt.headers ?? {}),
    Authorization: `Bearer ${token}`,
    'x-device-key': await getVisitorId(),
  } as Record<string, string>
  if (opt.json) {
    headers['content-type'] = 'application/json'
  }

  return headers
}

export const onErrorAbort = (e: Error) => {
  if (e.name === 'AbortError') {
    console.warn(e.message)
    return
  }
  throw e
}

export const configuredFetch = async (
  path: string | URL,
  init: RequestInit & { json?: Record<string | number, any> } = {}
) => {
  return fetch(new URL(path, api.baseUrl).toString(), await requestOptions(init))
}
