import { auth, authScopes } from './config'
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'
import { createLoginSession, logOutSession } from './session'

let clientCache: PublicClientApplication
function getClient() {
  if (clientCache === undefined) {
    clientCache = new PublicClientApplication({ ...auth })
  }
  return clientCache
}

async function initiateSignIn() {
  const client = getClient()
  await client.loginRedirect({
    scopes: authScopes.login,
    redirectUri: `${window.location.protocol}//${window.location.host}/`,
  })
}

async function checkLogin(): Promise<{
  isLoggedIn: boolean,
  loginMessage?: {message: string},
}> {
  let loginMessage
  const redirect = await handlingRedirect
  const client = getClient()
  let activeAccount = client.getActiveAccount()
  if (redirect) {
    activeAccount = redirect.account
  } else if (!activeAccount) {
    const accounts = client.getAllAccounts()
    activeAccount = accounts[0]
  }
  if (activeAccount) client.setActiveAccount(activeAccount)
  if (redirect && activeAccount) {
    loginMessage = await createLoginSession()
  }
  return { isLoggedIn: !!activeAccount, loginMessage }
}

function conflictedSessionLogout() {
  const client = getClient()
  localStorage.clear()
  client.logoutRedirect()
}

async function logout() {
  const client = getClient()
  localStorage.clear()
  await logOutSession()
  await client.logoutRedirect()
}

const getTokenConfig = { scopes: authScopes.token }
async function getToken() {
  try {
    return getTokenSilently()
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      return getTokenPopup()
    }
  }
}
async function getTokenSilently() {
  const r = await getClient().acquireTokenSilent(getTokenConfig)
  return r.idToken
}

async function getTokenPopup() {
  const r = await getClient().acquireTokenPopup(getTokenConfig)
  return r.idToken
}

function getUser() {
  const client = getClient()
  return client.getActiveAccount()
}

let handlingRedirect = getClient()
  .handleRedirectPromise()
  .catch((e) => {
    console.error(e)
  })

const switchUser = async () => {
  const client = getClient()
  return await client.loginRedirect({
    prompt: 'select_account',
    scopes: authScopes.login,
    redirectUri: `${window.location.protocol}//${window.location.host}/`,
  })
}

export { checkLogin, conflictedSessionLogout, logout, getUser, getToken, initiateSignIn, switchUser }
