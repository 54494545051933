import { requestTypeLabels } from './type-labels'

type PropertyName = string
type ValueLabelMap = Record<string | number, string>

const SELECT_LABELS = {
  Type: requestTypeLabels,
} as Record<PropertyName, ValueLabelMap>

export const LOGIN_CONFLICT_ERROR_MESSAGE = `You are already logged in from another device.`

export { SELECT_LABELS }
export { FORM_LABELS } from './form-labels'
export { YES_OR_NO_RADIO_OPTIONS } from './yes-or-no-radio-options'
export { ACCEPTED_MIME_TYPES } from './file-mime-types'