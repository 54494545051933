import { useState } from 'react'
import { Notification } from './type'

let count = 0
export const useRootNotifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>([])

  const removeWrap = (id: number, cb?: () => void) => () => {
    setNotifications((n) => n.filter((n) =>  n.id !== id))
    cb && cb()
  }

  const addNotification =
    (variant: Notification['variant']) => (notification: Omit<Notification, 'variant' | 'id'>) => {
      const id = count++
      const { duration='' } = notification
      const onClose = removeWrap(id, notification.onClose)

      if (variant === 'success' || duration) {
        setTimeout(onClose, duration || 3000)
      }
      const newNotification = {
        ...notification,
        id,
        variant,
        onClose,
        actionOnClick: removeWrap(id, notification.actionOnClick),
      }
      setNotifications((notifications) => [newNotification, ...notifications])
    }

  const hasNotification = (f: (n: Notification) => boolean) => notifications.some(f)

  return {
    notifications,
    info: addNotification('info'),
    warning: addNotification('warning'),
    error: addNotification('error'),
    success: addNotification('success'),
    hasNotification,
  }
}
