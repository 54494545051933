import * as React from "react"

export const Logo = (props: any) => (
  <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.498 38.654h9.04v-4.969h-9.04v-3.904h10l-3.317-5.443H0V48h20v-5.442H7.498v-3.904ZM32.587 24.338l-4.084 7.722-4.074-7.722h-7.972l8.4 14.316V48h7.189v-9.346l8.411-14.316h-7.87Z"
      fill="#3A3A4A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m0 17.09 48-8.272V0L0 17.09Z"
      fill="#FFE600"
    />
  </svg>
)
