import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context'
import {
  MotifButton,
  MotifFooter,
  MotifModal,
  MotifModalBody,
  MotifModalHeader,
} from '../../motif/components'
import { useNotifications } from '../Notifications'
import { LOGIN_CONFLICT_ERROR_MESSAGE } from '../../constants'
import { conflictedSessionLogout } from '../../lib/auth'
import { Modal } from '../modal'

export class ErrorBoundaryClass extends React.Component<
  PropsWithChildren<{ onError: (error: any, errorInfo: any) => void; onContinue?: () => void }>,
  { error?: Error }
> {
  constructor(props: any) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.props.onError(error, errorInfo)
  }

  static getDerivedStateFromError(e: Error) {
    return { error: e }
  }

  onContinue = (t: 'reload' | 'home') => {
    if (t === 'reload') {
      window.location.reload()
    } else {
      window.location.pathname = '/'
    }
  }

  render() {
    if (this.state.error) {
      const e = this.state.error

      return (
        <MotifModal show onClose={this.onContinue} size='xl' style={{ width: 700 }}>
          <MotifModalHeader>Error</MotifModalHeader>
          <MotifModalBody style={{ minHeight: 400, position: 'relative' }}>
            <p>{e.message}</p>
            <pre>{e.stack}</pre>
          </MotifModalBody>
          <MotifFooter style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
            <MotifButton variant='primary-alt' onClick={() => this.onContinue('reload')}>
              Reload
            </MotifButton>
            <MotifButton variant='primary-alt' onClick={() => this.onContinue('home')}>
              Home
            </MotifButton>
          </MotifFooter>
        </MotifModal>
      )
    }
    return this.props.children
  }
}

export const ErrorBoundary = (props: PropsWithChildren<{}>) => {
  const notifications = useNotifications()
  const { switchUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const onError = (error: any, errorInfo: any) => {
    notifications.error({ children: error.message })
  }

  const promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    const error = event.reason
    const status = error.res?.status

    if (!status) return

    const errorMessage = error.res?.data?.message
    let message
    switch (status) {
      case 400:
        if (errorMessage.includes('required property')) {
          const matches = errorMessage.match(/'([^']+)'/)
          message = (
            <FormattedMessage
              id='error.generic'
              defaultMessage='{field} is required field'
              values={{ field: matches || '' }}
            />
          )
        } else if (
          errorMessage.includes('match pattern') ||
          errorMessage.includes('match format')
        ) {
          const field = errorMessage.split(' ')[0]
          const errorField = field.split('.').slice(-1)[0]
          message = (
            <FormattedMessage
              id='error.generic'
              defaultMessage='{field} must be in correct format'
              values={{ field: errorField || '' }}
            />
          )
        } else {
          message = (
            <FormattedMessage
              id='error.generic'
              defaultMessage='Server error {message}'
              values={{ message: error?.res?.data?.message || '400' }}
            />
          )
        }
        break
      case 401:
        message = <FormattedMessage id='error.noauth' defaultMessage='Unauthenticated (401)' />
        break
      case 403:
        message = (
          <FormattedMessage
            id='error.unauthorized'
            defaultMessage='Unauthorized to perform action (403)'
          />
        )
        break
      case 404:
        message = (
          <FormattedMessage id='error.not_found' defaultMessage='Resource not found (404)' />
        )
        break
      case 409:
        message = (
          <FormattedMessage id='error.conflict' defaultMessage={LOGIN_CONFLICT_ERROR_MESSAGE} />
        )
        break
      default:
        message = (
          <FormattedMessage
            id='error.generic'
            defaultMessage='Server error ({code})'
            values={{ code: status || '-' }}
          />
        )
        break
    }

    const handleActionClick = () => {
      if (status === 409) {
        conflictedSessionLogout()
      } else {
        navigate('/')
      }
    }

    notifications.error({
      children: message,
      actionName: 'Home',
      actionOnClick: handleActionClick,
      onClose: handleActionClick,
    })

    if (status === 401) switchUser()
  }

  useEffect(() => {
    window.addEventListener('unhandledrejection', promiseRejectionHandler)
    return () => window.removeEventListener('unhandledrejection', promiseRejectionHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ErrorBoundaryClass onError={onError}>{props.children}</ErrorBoundaryClass>
}
