import type { Configuration } from '@azure/msal-browser'
import insight from './config/insight.json'

const hostObj = insight.hostKey.filter(hObj => {
  return hObj.host.includes(window.location.host)
})[0];

export const auth: Configuration = {
  auth: {
    clientId: hostObj.client_id,
    authority: hostObj.client_authority,
    redirectUri: `${window.location.protocol}//${window.location.host}/`,
    postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    tokenRenewalOffsetSeconds: 60 * 5,
  },
}

export const authScopes = {
  login: ['openid', 'profile', 'User.read'],
  token: ['User.read'],
}

export const api = {
  baseUrl: hostObj.api_base_url,
}
