import React, { useContext } from 'react'
import { FormattedMessage, IntlProvider, MessageFormatElement } from 'react-intl'
import { MotifProgressBar } from '@ey-xd/motif-react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { AppBar } from './components/AppBar'
import { Empty } from './components/Empty'
import { ErrorBoundary } from './components/ErrorBoundary'
import { Login } from './components/Login'
import { logout } from './lib/auth'
import {
  NotificationContext,
  Notifications,
  useRootNotifications,
} from './components/Notifications'
import { SideBar } from './components/SideBar'
import { Modal } from './components/modal'
import { AuthContext } from './context'
import { AbilityContext } from './context/permissions'
import { LOGIN_CONFLICT_ERROR_MESSAGE } from './constants'

const toPage = (loadFn: () => Promise<{ default: () => JSX.Element }>) => {
  const PageComponent = React.lazy(loadFn)
  return () => (
    <React.Suspense>
      <PageComponent />
    </React.Suspense>
  )
}

const AddIntakeRequestPage = toPage(() => import('./pages/AddIntakeRequestPage'))
const IntakeRequestListPage = toPage(() => import('./pages/IntakeRequestListPage'))
const RequestPage = toPage(() => import('./pages/RequestPage'))
const UserListPage = toPage(() => import('./pages/UserListPage'))
const ReportsPage = toPage(() => import('./pages/ReportsPage'))
const CostMonitoringPage = toPage(() => import('./pages/CostMonitoringPage'))
const InfraOptimizationPage = toPage(() => import('./pages/InfraOptimizationPage'))
const ScaleUpDownDatabasePage = toPage(() => import('./pages/ScaleUpDownPage'))

type AppProps = {
  localisedMessages: LocaleFile
  locale: string
}

export type LocaleFile = Record<string, MessageFormatElement[]>

const App: React.FC<AppProps> = ({ localisedMessages, locale }) => {
  const { user,isLoggedIn } = useContext(AuthContext)
  const { notifications, ...contextApi } = useRootNotifications()

  const handleOnIdle = async () => {
    logout()
  }


  useIdleTimer({
    timeout: 1000*60*30,
    debounce: 500,
    onIdle: handleOnIdle,
  })

  return (
    <IntlProvider messages={localisedMessages} locale={locale} defaultLocale='en'>
      <NotificationContext.Provider value={contextApi}>
        <ErrorBoundary>
          <Notifications notifications={notifications} />
          {isLoggedIn === true && (
            <div className='App'>
              <AppBar user={user} />
              <SideBar />
              <div className='content'>
                <AuthenticatedRoutes />
              </div>
            </div>
          )}
          {isLoggedIn === false && (
            <Login />
          )}
          {
            isLoggedIn === null &&(
              <div className='loading-view motif-middle-xs motif-center-xs'>
                <MotifProgressBar
                  hideLabel
                  circle
                  isIndeterminate />
                </div>
          )}
          
        </ErrorBoundary>
      </NotificationContext.Provider>
    </IntlProvider>
  )
}

export default App

function AuthenticatedRoutes() {
  const userAbility = useContext(AbilityContext)
  const { conflictedSessionLogout, loginMessage } = useContext(AuthContext)
  const readUsers = userAbility.can('read', 'User')
  const readRequests = userAbility.can('read', 'Request')
  const createRequests = userAbility.can('create', 'Request')
  const readReports = userAbility.can('read','Report')
  const readCostMonitoring = userAbility.can('read','CostMonitoring')
  const editInfra = userAbility.can('read','InfrastructureOptimization')  && userAbility.can('create','InfrastructureOptimization')// need to update with permissions

  let fallback = (
    <Empty>
      <FormattedMessage id='permissions.empty' defaultMessage='No available permissions' />
    </Empty>
  )

  if (readCostMonitoring && !readRequests && !readUsers) {
    fallback = <Navigate to='/cost-monitoring' replace={true} />
  }

  if (readRequests || (readRequests && readUsers)) {
    fallback = <Navigate to='/requests' replace={true} />
  }
  if (readUsers && !readRequests) {
    fallback = <Navigate to='/users' replace={true} />
  }
  if (editInfra) {
    fallback = <Navigate to='/infra-optimiztion' replace={true} />
  }

  if (loginMessage && loginMessage?.message === LOGIN_CONFLICT_ERROR_MESSAGE) {
    return (
      <Modal
        show={true}
        onClose={conflictedSessionLogout}
        title={'Login conflict'}
        size='lg'
        confirm={conflictedSessionLogout}
        disableFooter
      >
        <div className='import-file-upload-body'>
          <div className='motif-container'>
            <div className='motif-h4'>
              {LOGIN_CONFLICT_ERROR_MESSAGE}
            </div>
          </div>
        </div>
      </Modal>
    )
  }


  return (
    <Routes>
      {readUsers && <Route path='users' element={<UserListPage />} />}
      {readReports && <Route path='reports' element={<ReportsPage />} />}
      {readRequests && (
        <Route path='requests'>
          {createRequests && <Route path='new' element={<AddIntakeRequestPage />} />}
          <Route path=':requestId' element={<RequestPage />} />
          <Route path='' element={<IntakeRequestListPage />} />
        </Route>
      )}
      {readCostMonitoring && <Route path='cost-monitoring' element={<CostMonitoringPage />} />}
 
      {editInfra && <Route path='infra-optimiztion' element={<InfraOptimizationPage />} />}
      {editInfra && <Route path='scale-up-down-database' element={<ScaleUpDownDatabasePage />} />}


      <Route path='*' element={fallback} />
    </Routes>
  )
}
