import { FetchError, configuredFetch } from './request-utils'

export async function createLoginSession() {
  try {
    const res = await configuredFetch(`/session/login`, {
      method: 'POST',
      json: {}
    })
    if (!res.ok) throw new FetchError(res)
    const result = await res.json()
    return result
  } catch (e) {
    console.log('error in login', e)
  }
}

export async function logOutSession() {
  try {
    const res = await configuredFetch(`/session/logout`, {
      method: 'POST',
      json: {}
    })
    if (!res.ok) throw new FetchError(res)
    const result = await res.json()
    return result
  } catch (e) {
    console.log('error in logout', e)
  }
}
