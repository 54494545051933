import { MotifAvatar, MotifDropdown, MotifDropdownItem } from '../../motif/components'
import React, { useState, useContext, forwardRef } from 'react'
import { AuthContext, UserProps } from '../../context'
import c from './AppBar.module.css'
import { FormattedMessage } from 'react-intl'

type AppBarProps = {
  user?: UserProps | null
}

type MyAvatarProps = Pick<AppBarProps, 'user'> & {
  setMenuOpen: (value: React.SetStateAction<boolean>) => void
}

const userRolesMap:any = {
  superadmin: 'Admin',
  bpmo: 'BPMO',
  ct: 'CT approver',
  requester: 'Requester',
  cmadmin: 'CM Admin',
  analyticsadmin: 'Analytics Admin',
}

const MyAvatar = forwardRef<void, MyAvatarProps>((props, ref) => {
  return (
    <MotifAvatar userName={props.user?.userName} onClick={() => props.setMenuOpen((o) => !o)} />
  )
})

const AppBar = (props: AppBarProps) => {
  const { logout } = useContext(AuthContext)
  const [menuOpen, setMenuOpen] = useState(false)
  const userRoles = props.user?.Roles.map(role => userRolesMap[role.role])
  const avatar = <MyAvatar user={props.user} setMenuOpen={setMenuOpen} />
  return (
    <header className={c.appBar}>
      <img src='/logo.svg' alt='EY logo' className={c.logo} />
      <h3>
        <FormattedMessage defaultMessage='VIA Intake form' id='intakeFormHeader' />
      </h3>
      <div className={`motif-h6 ${c.userType}`}>You are logged in as {userRoles?.join(', ')}</div>
      <MotifDropdown open={menuOpen} trigger={avatar} handleClickOutside={() => setMenuOpen(false)}>
        <MotifDropdownItem>{props.user?.userName}</MotifDropdownItem>
        <MotifDropdownItem onClick={logout}>
          <FormattedMessage id='logoutCta' defaultMessage='Logout' />
        </MotifDropdownItem>
      </MotifDropdown>
    </header >
  )
}

export { AppBar }
