import { createContext, useState, useEffect, useMemo } from 'react'
import {
  checkLogin,
  conflictedSessionLogout,
  getUser as getAdUser,
  logout,
  initiateSignIn,
  switchUser,
} from '../../lib/auth'
import { AbilityContext } from '../permissions'
import { UserProps, AuthContextProps, AuthProviderProps } from './interfaces'
import { Ability } from '@casl/ability'
import { getPermissions } from '../../lib/permissions'
import { useNavigate } from 'react-router-dom'

const getUserFromDb = async () => ({ id: '0001', Roles: [{ role: 'superadmin' }] })

export let permission: any

const getUser = async () => {
  const status = await checkLogin()
  if (status.isLoggedIn) {
    const [accountInfo, userFromDb, permissions] = await Promise.all([
      getAdUser(),
      getUserFromDb(),
      getPermissions(),
    ])

    const userName = accountInfo?.username || '-'
    permission = permissions;
    let userRoles:{ role: string }[] = []
    permission.forEach((action:any) => {
      if(action.action === 'role') {
        userRoles.push({role: action.subject})
      }
    })
    if(userRoles.length>0){
      userFromDb.Roles = userRoles
    }
    return {
      userName,
      ...userFromDb,
      permissions,
      loginMessage: status.loginMessage,
    }
  }
  return null
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [loading, setLoading] = useState(0)
  const [user, setUser] = useState<UserProps | null>(null)
  const [ability, setAbility] = useState(new Ability())
  const [isLoadingRequired, setIsLoadingRequired] = useState<boolean>(false)
  const [isLoggedIn,setLoggedIn] = useState<boolean | null>(null);
  const [loginMessage, setLoginMessage] = useState<{message: string}>({ message: '' })
  const navigate = useNavigate()
  useEffect(() => {
    setLoading((l) => ++l)
    getUser().then((user) => {
      if(user) setLoggedIn(true);
      else setLoggedIn(false);
      setUser(user)
      setAbility(new Ability(user?.permissions as any))
      setLoading((l) => --l)
      setIsLoadingRequired(false)
      checkLogin().then((res) => {
        if (res.loginMessage) {
          setLoginMessage(res.loginMessage)
        }
      })
      isLoadingRequired && navigate('/')
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRequired])

  const authContextValue = useMemo(
    () => ({
      loading,
      user,
      logout,
      conflictedSessionLogout,
      initiateSignIn,
      switchUser,
      isLoggedIn,
      setIsLoadingRequired,
      loginMessage,
    }),
    [loading, user, isLoggedIn, loginMessage]
  )

  return (
    <AbilityContext.Provider value={ability}>
      <AuthContext.Provider
        value={authContextValue}
      >
        {children}
      </AuthContext.Provider>
    </AbilityContext.Provider>
  )
}

export const AuthContext = createContext<AuthContextProps>({
  loading: 0,
  user: null,
  logout: () => {},
  conflictedSessionLogout: () => {},
  initiateSignIn,
  switchUser,
  isLoggedIn: null,
  setIsLoadingRequired: () => {},
})

export * from './interfaces'
