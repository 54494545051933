import { MotifToast } from '../../motif/components'
import { Notification } from './type'
import c from './notifications.module.css'

type NotificationsProps = {
  notifications: Notification[]
}
export const Notifications = (props: NotificationsProps) => {
  return (
    <div className={c.fixed}>
      <div className={c.notifications}>
        {props.notifications.map((n, ix) => (
          <MotifToast key={ix} {...n} />
        ))}
      </div>
    </div>
  )
}
