import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App, { LocaleFile } from './App'
import { AuthProvider } from './context'
import './index.scss'

async function loadLocaleStrings(locale: string): Promise<LocaleFile> {
  const basePath = `./compiled-lang`
  switch (locale) {
    case 'fr':
      return import(`${basePath}/fr.json`) as any
    default:
      return import(`${basePath}/en.json`) as any
  }
}

async function startApplication() {
  const locale = 'en'
  const localisedMessages = await loadLocaleStrings(locale)
  const root = document.getElementById('root') as Element
  root.innerHTML = ''
  createRoot(root).render(
    <BrowserRouter>
      <AuthProvider>
        <App localisedMessages={localisedMessages} locale={locale} />
      </AuthProvider>
    </BrowserRouter>
  )
}

startApplication()
