const clientFormLabels = {
  ClientId: 'Client ID',
  ClientName: 'Client name',
  CustomClientName: 'Custom client name',
  Channel: 'Client relation',
  ClientType: 'Client type',
  ClientLocationHq: 'Client Location HQ',
} as const

const engagementFormLabels = {
  ClientAnnualRiskRevenue: 'Annual Risk Revenue from Client (in USD)',
  EngagementId: 'Engagement ID',
  EngagementManager: 'Engagement manager',
  EngagementManagerEmail: 'Engagement Manager Email',
  EngagementType: 'Engagement type',
  EngagementPartner: 'Engagement partner',
  EngagementPartnerEmail: 'Engagement Partner Email',
  EngagementRegion: 'Engagement region',
  OpportunityId: 'Opportunity ID',
  ExpectRiskWorkTotal: 'Expected risk work total',
  ExpectedAuditRevenueYear: 'Expected Internal Audit Revenue Per Year',
  ExpectedTechnologyFee: 'Expected technology fee',
  ExpectedTotalDeal: 'Expected total deal size considering services and technology size',
} as const

const requestFormLabels = {
  AdministratorEmail: 'Administrator email',
  Attachments: 'Attachments',
  AttachmentComments: 'Attachment Comments',
  Category: 'Category',
  ClientAnalyticalTool: 'Does the client currently use any analytical tool?',
  ClientAuditManagementTool: 'Does the client currently use any audit management tool?',
  ClientErp: 'What ERP does the client use?',
  ClientIaFunctionSize: 'What is the size of their IA function?',
  ClientRoadMap: 'Is there a documented digital roadmap for the client?',
  ClientUserIds: 'Client user IDs',
  AdditionalComments: 'Additional Comments',
  DeliveryModel: 'What is the Intended Delivery Model?',
  DemoDuration: 'What is the duration in hours of the demo?',
  EmoTargetAudience: 'Who is the EMO target audience?',
  ModuleMapping: 'Module mapping',
  SelectedModules:'Selected modules',
  PreferredServerLocation: 'Where is the preferred server location?',
  NewServerLocation: 'Where is the new server location?',
  SubscriptionEnd: 'Subscription end',
  SubscriptionStart: 'Subscription start',
  DemoProvided: 'Has there been a demo provided?',
  InitialSetupSupport: 'Do you require support with initial setup?',
  Type: 'Type of request',
  SubscriptionStartDate: 'Subscription Start Date',
  SubscriptionEndDate: 'Subscription End Date',
  TentativeDemoDate: 'Tentative Demo Date',
  PursuitDueDate: 'Pursuit Due Date',
  ClientInfoUsedForDemo: 'Is client information used for demo?',
  CategorizationOfPursuitSupport: 'Categorization of pursuit support',
} as const

export const FORM_LABELS = {
  ...clientFormLabels,
  ...engagementFormLabels,
  ...requestFormLabels,
} as Record<string, string>
